<script setup lang="ts">
  const props = defineProps<{
    playing: boolean,
    mute: boolean
  }>();
  const emit = defineEmits(['player:play', 'player:pause', 'player:mute-off', 'player:mute-on']);
  const pause = () => {
    emit('player:pause');
  };
  const play = () => {
    emit('player:play');
  };
  const muteOn = () => {
    emit('player:mute-on');
  };
  const muteOff = () => {
    emit('player:mute-off');
  };
</script>

<template>
  <div>
    <IconPauseButtonModalGallery
      class="player-icon h20 white pointer"
      v-if="props.playing"
      @click="pause"
    />
    <IconPlayButton
      class="player-icon h20 white pointer"
      v-if="!props.playing"
      @click="play"
    />
  </div>
  <div>
    <IconVolumeOff
      class="player-icon white pointer"
      v-if="props.mute"
      @click="muteOff"
    />
    <IconVolumeOn
      class="player-icon white pointer"
      v-if="!props.mute"
      @click="muteOn"
    />
  </div>
</template>

<style scoped>
  .player-icon {
    width: 32px;
    height: 32px;
    &.white {
      color: white
    }
    &.h20 {
      height: 22px;
    }
  }
</style>
